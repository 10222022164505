@font-face {
  font-family: 'DragonWarrior';
  src: url('dragon-warrior-nes.ttf');
}

@font-face {
  font-family: 'PixArrows';
  src: url('PixArrows.ttf');
}

.button {
  cursor: pointer !important;
}

.sprite{
  cursor: default !important;
}

img { 
  cursor: pointer !important;
}

* {
  font-family: DragonWarrior;
  font-size: 1vw;
  cursor: auto !important;
}



ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li>a {
  padding: 8px 0;
  width: 100%;
}

li {
  margin: 4px 0;
}

a {
  font-size: 0.8vw;
}


a:link {
  text-decoration: none !important;
  color:white !important;
}

a:visited {
  text-decoration: none !important;
  color:white !important;
}

a:hover {
  text-decoration: none !important;
  color:white !important;
}

a:active {
  text-decoration: none !important;
  color:white !important;
}

.a:link{
  color: #99e550 !important;
}

.a:visited{
  color: #99e550 !important;
}

.a:hover{
  color: #99e550 !important;
}

.a:active{
  color: #99e550 !important;
}



body {
  height: 100vh;
  background: #000000 !important;
}
.content {
  white-space: pre-wrap;
}

.content > img {
  padding-top: 40px;
  max-width: 70%;
  max-height: 20vh;
  display: block;
  margin: 0 auto;
}

.content > a > img {
  padding-top: 40px;
  max-width: 70%;
  max-height: 50vh;
  display: block;
  margin: 0 auto;
}

@media (min-width: 1601px) {
  .container {
    border-width: 8px !important;
  }
}

.container {
  border-radius: 3px;
  padding: 0;
}


.component {
  padding: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Main {
  width: 40vw;
  height: 100%;
}

.content {
  font-size: 0.55vw;
  text-align: left !important;
  padding: 8px 0px;
}

.contentDate {
  font-size: 0.7vw
}

.gContent {
  font-size: 0.5vw;
  text-align: left !important;
}

.gContentDate {
  font-size: 0.35vw
}

.Guestbook,
.Bookmark {
  width: 15.6vw;
  height: 100%;
}

.bcontent {
  text-align: left !important;
}

li>a:hover:after {
  content: " ◀";
}

.sprite {
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  width: 90%;
}

.center-div {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  padding: 2px;
  background: #000000;
}

.cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: #00000091;
}

.nes-container.is-dark::after {
  position: static !important;
  background-color: #000000 !important;
}

.nes-container.is-dark {
  background-color: #000000 !important;
}

.nes-container.is-dark.with-title > .title{
  background-color: #000000 !important;
}

.noSelect *{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect *:focus {
  outline: none !important;
}

.allowSelect {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.allowSelect * {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

::-moz-selection { /* Code for Firefox */
  color: #000000;
  background: white;
}

::selection {
  color: #000000;
  background: white;
}

.portfolioContainer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2vh;
}

.portfolioGallery {
  display: flex;
  max-width: 100%;
  justify-content:space-evenly;
}

.portfolioThumbnailWrapper{
  display: flex;
  max-width: 23%;
  aspect-ratio : 1 / 1;
}

.portfolioThumbnail {
  flex-grow: 1;  
  aspect-ratio : 1 / 1;
  object-fit: cover;
}

.detailContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  max-width: 100%;
  height: 100%;
}

.detailImage{
  max-width: 100%;
  max-height: 50%;
}

a {
  cursor: pointer !important;
}

.pointer {
  cursor: pointer !important;
}

.galleryNav {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.arrow{
  cursor: pointer !important;
}

.box {
  flex: 1;
  display: flex;
  justify-content: center;
}

.X {
  font-size: 2vw;
  cursor: pointer !important;
  flex: 1;
  text-align: left;
}

.dTitle{
  flex: 1;
  margin-right: auto;
}

.arrows{
  margin-left: auto;
  margin-right:0;
  display:flex;
  gap: 20px;
}

.galleryNavArrows{
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-left: auto;
}

@media only screen and (max-width: 2000px) {
  .portfolioThumbnailWrapper{
    display: flex;
    max-width: 30%;
    aspect-ratio : 1 / 1;
  }
  
  .portfolioThumbnail {
    aspect-ratio : 1 / 1;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  
}

@media only screen and (max-width: 800px) {
  #nikki{
    display: none;
    cursor: default !important;
  }

  * {
    font-family: DragonWarrior;
    font-size: 5vw;
    cursor: auto !important;
  }

  a {
    font-size: 3.8vw;
  }

  .container {
    border-width: 8px !important;
  }

  .content {
    font-size: 2.5vw;
    text-align: left !important;
    padding: 8px 0px;
  }
  
  .gContent {
    font-size: 2vw;
    text-align: left !important;
  }

  .gContentDate {
    font-size: 1.3vw
  }

  .contentDate {
    font-size: 2vw
  }

  .component{
    height: auto
  }

  .sprite{
    max-height: 20vw;
    max-width: 100%;
    object-fit: contain;
    width: auto;
    height: auto;
  }
  
  .Main {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .Guestbook,
  .Bookmark {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }

  .GuestBookTitle{
    display: none !important
  }

  .arrow{
    font-size: 5vw !important;
  }

  .four{
    font-size: 2vw !important;
  }

  .six{
    font-size: 3vw !important;
  }

  .X {
    font-size: 5vw !important;
  }

  .center-div {
    width: 90%;
    position: fixed !important;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    padding: 2px;
    background: #000000;
  }

  .cover {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #00000091;
  }

  .formButtons > *{
    font-size: 2vw !important;
  }

}

