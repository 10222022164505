.App {
  text-align: center;
  display: flex;
  justify-content: center;
  height: 95vh;
  padding-top: 5vh;
}

.AppMobile {
  text-align: center;
  justify-content: center;
  height: 95vh;
  padding: 4px;
}

canvas{
  width:100%;
  height:100%;
  position: absolute;
  z-index: 1;
  pointer-events:none;
}

#kirika{
  display:none;
}